<template>
  <footer>
    <div  class="yh-col-yth">
      <input type="checkbox" id="yth-menu" class="menu">
      <label for="yth-menu">Youth</label>
      <div class="yth-menu-content">
        <ul class="yh-lst-zero">
          <li><router-link to="/Youth">Youth</router-link></li>

        </ul>
      </div>
    </div>
    <div class="yh-col-yth">
      <input type="checkbox" id="cm-menu" class="menu">
      <label for="cm-menu">Community</label>
      <div class="cm-menu-content">
        <ul class="yh-lst-zero">
          <li><router-link to="/Youth">Community</router-link></li>
       
        </ul>
      </div>
    </div>
    <div class="yh-col-yth">
      <input type="checkbox" id="lrn-menu" class="menu">
      <label for="lrn-menu">Learning</label>
      <div class="lrn-menu-content">
        <ul class="yh-lst-zero">
          <li><router-link to="/Youth">Learning</router-link></li>
          
        </ul>
      </div>
    </div>
    <div class="yh-col-yth">
      <input type="checkbox" id="hlp-menu" class="menu">
      <label for="hlp-menu">Helpful Links</label>
      <div class="hlp-menu-content">
        <ul class="yh-lst-zero">
          <li>Youth Highland</li>
          <li>The Pillar Box</li>
          <li>High Street</li>
          <li>Dingwall</li>
          <li>IV15 9HA</li>
          <li>EMAIL: info@youthhighland.org.uk</li>
          <li>TEL: 01349865186</li>

        </ul>
      </div>
    </div>
  </footer>
  <div class="yh-btm-cw">
    <small>&copy; 2022 Youth Highland - SC027712. All rights reserved.</small>
  </div>
</template>
<script>
export default {
  setup() {
    
  },
}
</script>
<style>

</style>