import axios from 'axios';

export default {
  namespaced: true,
  state: {
    pageName: 'Home',
    detail: null,
    dataRetrieved: false,
    articleData: [],
  },
  mutations: {
    updatePageName(state, name) {
      state.pageName = name;
    },
    updateDetail(state, storyDetail) {
      state.detail = storyDetail;
    },
    updateArticleData(state, articles) {
      state.articleData = articles;
    },
    updateDataRetrieved(state) {
      state.dataRetrieved = true;
    }
  },
  actions: {
    getArticle({ commit }, id) {
      const urlString = `http://localhost:3000/newsStories?id=${id}`;
      axios.get(urlString)
        .then((result) => commit('updateDetail', result.data))
        .catch();
    },
    getArticles({ commit }) {
      const urlString = 'http://localhost:3000/newsStories';
      axios.get(urlString)
        .then((result) => commit('updateArticleData', result.data))
        .catch();
      commit('updateDataRetrieved');
      console.log
    },
  },
  getters: {
  },
};