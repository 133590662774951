<template>
  <nav class="yh-top-navbar">
    <ul class="yh-social-btns yh-lst-zero">
      <li><router-link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"/></svg></router-link></li>
      <li><a href="https://facebook.com/youthhighland" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"/></svg></a></li>
      <li><a href="https://instagram.com/youthhighland" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.233 5.488c-.843-.038-1.097-.046-3.233-.046s-2.389.008-3.232.046c-2.17.099-3.181 1.127-3.279 3.279-.039.844-.048 1.097-.048 3.233s.009 2.389.047 3.233c.099 2.148 1.106 3.18 3.279 3.279.843.038 1.097.047 3.233.047 2.137 0 2.39-.008 3.233-.046 2.17-.099 3.18-1.129 3.279-3.279.038-.844.046-1.097.046-3.233s-.008-2.389-.046-3.232c-.099-2.153-1.111-3.182-3.279-3.281zm-3.233 10.62c-2.269 0-4.108-1.839-4.108-4.108 0-2.269 1.84-4.108 4.108-4.108s4.108 1.839 4.108 4.108c0 2.269-1.839 4.108-4.108 4.108zm4.271-7.418c-.53 0-.96-.43-.96-.96s.43-.96.96-.96.96.43.96.96-.43.96-.96.96zm-1.604 3.31c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667zm4.333-12h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm.952 15.298c-.132 2.909-1.751 4.521-4.653 4.654-.854.039-1.126.048-3.299.048s-2.444-.009-3.298-.048c-2.908-.133-4.52-1.748-4.654-4.654-.039-.853-.048-1.125-.048-3.298 0-2.172.009-2.445.048-3.298.134-2.908 1.748-4.521 4.654-4.653.854-.04 1.125-.049 3.298-.049s2.445.009 3.299.048c2.908.133 4.523 1.751 4.653 4.653.039.854.048 1.127.048 3.299 0 2.173-.009 2.445-.048 3.298z"/></svg></a></li>
      <li><a href="https://twitter.com/youthhighland1" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z"/></svg></a></li>
      <li><a href="https://f3642e.myshopify.com" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3H3.21922L6.78345 17.2569C5.73276 17.7236 5 18.7762 5 20C5 21.6569 6.34315 23 8 23C9.65685 23 11 21.6569 11 20C11 19.6494 10.9398 19.3128 10.8293 19H15.1707C15.0602 19.3128 15 19.6494 15 20C15 21.6569 16.3431 23 18 23C19.6569 23 21 21.6569 21 20C21 18.3431 19.6569 17 18 17H8.78078L8.28078 15H18C20.0642 15 21.3019 13.6959 21.9887 12.2559C22.6599 10.8487 22.8935 9.16692 22.975 7.94368C23.0884 6.24014 21.6803 5 20.1211 5H5.78078L5.15951 2.51493C4.93692 1.62459 4.13696 1 3.21922 1H2ZM18 13H7.78078L6.28078 7H20.1211C20.6742 7 21.0063 7.40675 20.9794 7.81078C20.9034 8.9522 20.6906 10.3318 20.1836 11.3949C19.6922 12.4251 19.0201 13 18 13ZM18 20.9938C17.4511 20.9938 17.0062 20.5489 17.0062 20C17.0062 19.4511 17.4511 19.0062 18 19.0062C18.5489 19.0062 18.9938 19.4511 18.9938 20C18.9938 20.5489 18.5489 20.9938 18 20.9938ZM7.00617 20C7.00617 20.5489 7.45112 20.9938 8 20.9938C8.54888 20.9938 8.99383 20.5489 8.99383 20C8.99383 19.4511 8.54888 19.0062 8 19.0062C7.45112 19.0062 7.00617 19.4511 7.00617 20Z" fill="#0F0F0F"/></svg></a></li>

    </ul>
    <ul class="yh-signin yh-lst-zero">    
      <li><router-link to="/about">About</router-link></li>
      <li><router-link to="/blog">Blog</router-link></li>
      <li><router-link to="/links">Links</router-link></li>
      <!--<li><router-link to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.119 16.064c2.293-.53 4.427-.994 3.394-2.946-3.147-5.941-.835-9.118 2.488-9.118 3.388 0 5.643 3.299 2.488 9.119-1.065 1.964 1.149 2.427 3.393 2.946 1.985.458 2.118 1.428 2.118 3.107l-.003.828h-1.329c0-2.089.083-2.367-1.226-2.669-1.901-.438-3.695-.852-4.351-2.304-.239-.53-.395-1.402.226-2.543 1.372-2.532 1.719-4.726.949-6.017-.902-1.517-3.617-1.509-4.512-.022-.768 1.273-.426 3.479.936 6.05.607 1.146.447 2.016.206 2.543-.66 1.445-2.472 1.863-4.39 2.305-1.252.29-1.172.588-1.172 2.657h-1.331c0-2.196-.176-3.406 2.116-3.936zm-10.117 3.936h1.329c0-1.918-.186-1.385 1.824-1.973 1.014-.295 1.91-.723 2.316-1.612.212-.463.355-1.22-.162-2.197-.952-1.798-1.219-3.374-.712-4.215.547-.909 2.27-.908 2.819.015.935 1.567-.793 3.982-1.02 4.982h1.396c.44-1 1.206-2.208 1.206-3.9 0-2.01-1.312-3.1-2.998-3.1-2.493 0-4.227 2.383-1.866 6.839.774 1.464-.826 1.812-2.545 2.209-1.49.345-1.589 1.072-1.589 2.334l.002.618z"/></svg></router-link></li>-->
    </ul>
  </nav>

<router-view></router-view>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      toggle: true,
    }
  },
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Oxygen&family=Poppins&display=swap');

:root {
  --yh-black: #400000;
  --yh-drkred: #800000;
  --yh-less-red: #E60000;
  --yh-link: #BF0000;
  --white: #f7f8fa;
  --font-size: 1.3rem;
  --title-font: Poppins, Publica, Serif;
  --section-txt-font: Rubik, Arial, sans-serif;
  --title-size: 2.5rem;
  --section-txt: 2rem;
  --section-brdr-clr: red;
  --mono: monospace;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--title-font);
  font-size: 17px;
  color: var(--yh-black);
}

body a{
  text-decoration: none;
  cursor: pointer;
  color: var(--yh-link);
}

body p{
  font-family: var(--section-txt-font);
  font-size: 14px;
}

body h1 h2 {
  font-family: var(--title-font);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.yh-brdr-right {
  border-left: none;
  border-right: 3px solid var(--section-brdr-clr);
}

.yh-brdr-left {
  border-right: none;
  border-left: 3px solid var(--section-brdr-clr);
}

.yh-divider {
  margin-left: 30vh;
  margin-right: 30vh;
  padding: 0;
  height: 3px;
  background-color: var(--yh-drkred);
}

.yh-font-titles {
  font-family: var(--title-font);
}

.yh-top-navbar {
  display: flex;
  padding: 1rem;
  margin-left: 30vh;
  margin-right: 30vh;
  justify-content: space-between;
  align-items: center;
}

.yh-lst-zero {
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 1rem;
}

.yh-social-btns {
  display: inline-flex;
  justify-content: flex-start;
}

.yh-signin{
  display: inline-flex;
  justify-content: flex-end;
  margin-left: 1rem;
}

.yh-news-flash {
  background-color: white;
  margin-left: 10vw;
  margin-right: 10vw;
  height: 30px;
  color: black;
  text-align: center;
}

.yh-news-flash p {
  font-size: 14px;
}

.yh-logo {
  display:flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.yh-intro-section{
  display: grid;
  width: 100%;
  grid-template-columns: 10vw 1fr 10vw;
  grid-template-rows: 1fr;
}

.yh-menu-bar {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  background-color: white;
  height: fit-content;
  /*position: -webkit-sticky;
  position: fixed;*/
  top: -1px;
  padding: 1rem;
  z-index: 10;
  border-radius: 0px 0px 10px 10px;
}

.yh-menu-bar ul {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 2rem;
}

.yh-menu-highlight {
  color: var(--yh-less-red);
  font-weight: bold;
}

.yh-video-banner {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 400px;
}

.yh-video-banner img{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /*margin: -1px 0 0 -1px;*/
  object-fit: cover;
  padding: 0px;
  position: absolute;
  width: 100%;
}

.yh-video-banner video{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /*margin: -1px 0 0 -1px;*/
  object-fit: cover;
  padding: 0px;
  position: absolute;
  width: 100%;
  height: 400px;
}
.yh-content-grd{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-template-columns: 1fr;
  grid-template-rows: 20vh 20vh;
  grid-gap: 3px;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 30px;

}

.yh-image-tile{
  object-fit: cover;
  width: 300px;
  height: 300px;
}

.yh-image-tile-alt{
  object-fit: contain;
  width: 300px;
  height: 300px;
}

.section-row{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display:flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.header-title{
  font-family: var(--title-font);
}

.section-title{
  /* width: 80px; */
  height: 300px;
  /* align-items: baseline; */
  /* align-self: flex-start; */
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  /* border-right: 1px black solid; */
  /*font-family: var(--title-font); */
  font-family: var(--section-txt-font);
  font-size: var(--title-size);
}

.section-title p{
  padding: 0;
  margin: 0;
}

.section-text{
  width: 300px;
  height: fit-content;
}

.section-page-text{
  width: 500px;
  height: fit-content;
}

.section-text h2{
  text-align: center;
  font-family: var(--title-font);
  font-weight: bold;
  font-size: var(--section-txt);
}

.section-feature{
  
}

.section-feature img {
 
}

.section-sub-row {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display:flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;
}

.section-substory {
  width: 300px;
  /* height: 300px; */
  margin-bottom: 15px;
}

.yh-s-contain{
  /*background-image: url("public/images/youth-protest.jpg" );*/
  background-repeat: no-repeat;
  background-size: cover;
}

.yh-signup {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: rgba(128, 0, 0, 0.8);
  height: 200px;
  width: 100%;
}

.crumbs {
  text-align: center;
  margin: auto;
}

.yh-section-more {
  font-style: normal;
  font-weight: 400;
}

.yh-signup h3 {
  color: white;
  font-family: var(--title-font);
  font-size: 18x;
}

.reverse-title{
  order: 3;
}

.reverse-text{
  order: 2;
}

.reverse-feature{
  order: 1;
}

.justify-space-between {
  justify-content: space-between;
}

.yh-btm-cw{
  padding: 1rem;
  background-color: var(--yh-black);
  height: 40px;
  text-align: center;
  color: var(--white);
}

footer{
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 3rem;
  background-color: var(--yh-drkred);
  color: var(--white);
  padding-bottom: 30px;
  padding-top: 30px;
  /* padding-left: 12px; */
}

footer ul{
  text-decoration: none;
  border-left: 1px solid var(--yh-less-red);

}

footer ul li {
  padding-left: 12px;
  font-family: var(--section-txt-font);
  font-weight: 200;
  font-size: 12px;
}

footer p{
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2px;
}

.menu {
  display: none;
}

footer label{
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2px;
  text-align: center;
}

@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
  
  .yh-top-navbar {
  display: flex;
  padding: 1rem;
  margin-left: 0vh;
  margin-right: 30vh;
  justify-content: space-between;
  align-items: center;
}

  .yh-news-flash {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    background-color: white;
    margin-left: 5vw;
    margin-right: 5vw;
    height: 10px;
    color: black;
    font-size: 10 px;
    text-align: center;
  }

  .yh-menu-bar {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  background-color: white;
  height: fit-content;
  /*position: -webkit-sticky;
  position: fixed;*/
  top: -1px;
  padding: 1rem;
  z-index: 10;
  border-radius: 0px 0px 10px 10px;
}

  .section-row {
    justify-content: center;
  }

  .section-title {
    transform: rotate(0deg);
    writing-mode: horizontal-tb;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--yh-drkred);
    height: 100%;
  }

  .section-sub-row {
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }

  .section-substory:first-child{
    margin-top: 30px;
  }

  .section-feature img {
    max-width: 100%;
    min-width: 300px;
    height: auto;
    margin-top: 10px;
    margin-top: 8px;
  }

  .reverse-title{
    order: 1;
  }
  
  .reverse-text{
    order: 2;
  }
  
  .reverse-feature{
    order: 3;
  }

  footer {
    flex-flow: column;
    text-align: center;
    gap: 0.5rem;
    /* width: 100%; */
  }

  .yth-menu-content {
    max-height: 0;
    overflow: hidden;
  }

  .cm-menu-content {
    max-height: 0;
    overflow: hidden;
  }

  .lrn-menu-content {
    max-height: 0;
    overflow: hidden;
  }
  .hlp-menu-content {
    max-height: 0;
    overflow: hidden;
  }

  #yth-menu:checked ~ .yth-menu-content {
    max-height: 100%;
    transition: 1s;
  }

  #cm-menu:checked ~ .cm-menu-content {
    max-height: 100%;
    transition: 1s;
  }

  #lrn-menu:checked ~ .lrn-menu-content {
    max-height: 100%;
    transition: 1s;
  }

  #hlp-menu:checked ~ .hlp-menu-content {
    max-height: 100%;
    transition: 1s;
  }

  /*.yh-lst-zero input:checked{
    max-height: 100%;
  }*/

  .yh-btm-cw{
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 800px) {
  body {
    font-size: 16px;
  }
}

@media (min-width: 956px) {
  body {
    font-size: 18px;
  }
}

</style>
