<template>
  <div class="yh-content-grd">
    <div class="section-row" id="#learning">
      <div class="section-title yh-brdr-left yh-font-titles">
        LEARNING
      </div>
      <div class="section-text">
        <h2>{{ l_HeaderArticle.title }}</h2>
        <p>{{ l_HeaderArticle.summary }}</p>
        <p><router-link :to="{name: 'Article', params:{ id: l_HeaderArticle.id }}">...more</router-link></p>
      </div>
      <div class="section-feature">
        <img v-bind:src="`${ l_HeaderArticle.link }`" style="max-width: 400px" />
      </div>
    </div>
    <div v-if="loading">
      <p>Loading</p>
    </div>
    <div class="section-sub-row" v-else>
      <div class="section-substory" v-for="item in l_Articles" :key="item.id">
        <img class="yh-image-tile" v-bind:src="`${ item.link }`" style="max-width: 300px" />{{ item.title }}
        <p><router-link :to="'/Article/' + item.id">...more</router-link></p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  setup() {
    
  },
  data() {
    return {
      l_HeaderArticle: {
        title: "",
        summary: "",
        link: "",
        id: 0,
      },
      l_Articles: [],
      loading: false,
    }
  },
  async mounted() {
    var tmp_Articles;
    try {
      const header_response = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[HeaderArticle][$eq]=true&populate[categories][fields][0]=name&populate[articleImage][fields][0]=url&filters[categories][name][$eq]=Learning&sort[0]=publishedAt%3ADesc');
      this.l_HeaderArticle.title = header_response.data.data[0].attributes.Title;
      this.l_HeaderArticle.summary = header_response.data.data[0].attributes.summary;
      this.l_HeaderArticle.link = "https://api.youthhighland.org.uk" + header_response.data.data[0].attributes.articleImage.data.attributes.url;
      this.l_HeaderArticle.id = header_response.data.data[0].id;
    } catch (error)
    {
      this.error = error;
    }
    try {
      const l_Response = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[categories][name][$eq]=Learning&populate[articleImage][fields][0]=url&pagination[page]=1&pagination[pageSize]=3');
      tmp_Articles = l_Response.data;
    } catch(error)
    {
      this.error = error;
    }
    tmp_Articles.data.forEach(element =>{
      var l_Article = {
        title: "",
        summary: "",
        link: "",
        id: "",
      }
      l_Article.title = element.attributes.Title;
      l_Article.summary = element.attributes.summary;
      if(element.attributes.articleImage.data.attributes.url != null){
        l_Article.link = "https://api.youthhighland.org.uk" + element.attributes.articleImage.data.attributes.url;
      } else {
        l_Article.link = null;
      }
      l_Article.id = element.id;
      this.l_Articles.push(l_Article);
    });
  },
  created() {
    
  },
    
}
</script>
<style>

</style>