/*import { KnownArgumentNamesOnDirectivesRule } from 'graphql/validation/rules/KnownArgumentNamesRule';*/
import { createRouter, createWebHistory } from 'vue-router';
import homePage from '../views/Home.vue';
import displayArticle from '../components/DisplayArticle';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: homePage,
  },
  {
    path: '/hubs',
    name: 'HUBS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hubs.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/youth',
    name: 'Youth',
    component: () => import('../views/Youth.vue'),
  },
  {
    path: '/links',
    name: 'links',
    component: () => import('../views/UsefulLinks.vue'),
  },
  {
    path: '/community',
    name: 'Community',
    component: () => import('../views/Community.vue'),
  },
  {
    path: '/learning',
    name: 'Learning',
    component: () => import('../views/Learning.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/blog.vue'),
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: displayArticle,
    props: true,
    beforeEnter(to, from, next) {
      const isValidId = Number.isInteger(Number(to.params.id));
      console.log("in router");
      console.log(to.params.id);
      next(isValidId);
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
