<template>
<div>
  <div class="yh-intro-section">
    <div class="yh-menu-bar">
      <ul class="yh-lst-zero yh-font-titles">
        <li><router-link to="/Youth">Youth</router-link></li>
        <li><router-link to="/Community">Community</router-link></li>
        <li><router-link to="/Learning">Learning</router-link></li>
        <li><router-link to="/hubs">Hubs</router-link></li>
      </ul>
    </div>
    <div class="yh-pg-bnr">
      <img :src="mainArticle.image" style="height: 350px;" />
      <div class="yh-pg-title ">
        <div class="yh-title" >
          <p class="txt-bold">{{mainArticle.title}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="yh-main-story mrgn-lft-10wv pg-wdth">
    <div class="yh-stry-intro">
    </div>
    <aside>
      <!--<div class="yh-sde-col">
        <div class="pg-txt-align-cntr">
          <h2>About</h2>
        </div>
        <div class="yh-sde-img">
          <img src="https://via.placeholder.com/150" />
        </div>
        <p>This is an aside. And there is text here or a picture.</p>
        <div class="pg-btm-link pg-txt-align-cntr pg-no-underline txt-upper pd-1">
            <a href="" class="yh-fnt-clr">Discover More</a>
        </div>
      </div>-->
    </aside>
    <div class="yh-stry-bdy">
      <markDown :source="mainArticle.body" />
    </div>
  </div>
  <div class="yh-pg-ftr">
    <p class="yh-title-ftr">Related Articles</p>
  </div>
  <div class="mrgn-lft-10wv txt-bold rel-art-btm">
    <div class="yh-content-grd"></div>
    <div v-if="loading">
      <p>Loading</p>
    </div>
    <div class="section-sub-row pg-wdth" v-else>
      <div class="section-substory" v-for="item in relatedArticles" :key="item.id">
        <img class="yh-image-tile" v-bind:src="`${ item.image }`"  />
        
        <p><a :href="`/article/${item.id}`">{{item.title}} <div class="yh-section-more">...more</div></a></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import markDown from 'vue3-markdown-it';
import axios from 'axios';

export default {
  data() {
    return {
      mainArticle: {
        id: 0,
        title: "",
        body: "",
        image: "",
        category:"",
      },
      relatedArticles: [],
      source: '## One of doof',
      formatBodyTxt: "",
      routeParam: this.$route.params.id,
      }
  },
  components: {
    markDown,
  },
  methods: {
    
  },
  setup() {

  },
  async mounted() {
    try {
      const a_Response = await axios.get('https://api.youthhighland.org.uk/api/articles/' + this.routeParam);
      console.log(a_Response);
      this.mainArticle.title = a_Response.data.data.attributes.Title;
      this.mainArticle.body = a_Response.data.data.attributes.Body;
      this.mainArticle.id = a_Response.data.data.attributes.id;
     
    } catch(error)
    {
      this.error = error;
    }
    try {
      const articleDetail = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[id][$eq]=' + this.routeParam + '&populate=*&pagination[page]=1&pagination[pageSize]=3');
      console.log(articleDetail);
      this.mainArticle.image = "https://api.youthhighland.org.uk" + articleDetail.data.data[0].attributes.articleImage.data.attributes.url;
      this.mainArticle.category = articleDetail.data.data[0].attributes.categories.data[0].attributes.name;
    } catch(error)
    {
      this.error = error;
    }
    var tmp_Articles;
    try {
      const relatedResponse = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[categories][name][$eq]=' + this.mainArticle.category + '&populate[articleImage][fields][0]=url&pagination[page]=1&pagination[pageSize]=3');
      tmp_Articles = relatedResponse.data;

    } catch(error)
    {
      this.error = error;
    }
    tmp_Articles.data.forEach(element =>{
      var relatedArticle = {
        title: "",
        summary: "",
        link: "",
        id: "",
      }
      relatedArticle.title = element.attributes.Title;
      relatedArticle.summary = element.attributes.summary;
      relatedArticle.image = "https://api.youthhighland.org.uk" + element.attributes.articleImage.data.attributes.url;
      relatedArticle.id = element.id;
      this.relatedArticles.push(relatedArticle);
    });
  },
}
</script>

<style scoped>
.mrgn-lft-10wv {
  margin-left: 10vw;
}

.pg-no-underline {
  text-decoration: none;
}

.pg-txt-align-cntr {
  text-align: center;
}

.txt-bold {
  font-weight: 700;
}

.txt-upper {
  text-transform: uppercase;
}

.txt-title {
  font-size: 52px;
}

.pg-wdth {
  width: 83.3334%;
}

.pd-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}


.yh-pg-bnr {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 350px;
}

.yh-pg-bnr img{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  object-fit: cover;
  padding: 0px;
  position: absolute;
  width: 100%;
}

.yh-pg-title {
  position: absolute;
  top: 360px;
  height: 100px;
  width: 90%;
  background-color: red;
  color: white;
}

.yh-title {
  margin: 0;
  top: 50%;
  margin-left: 10vw;
  z-index: 10;
  transform: translateY(-50%);
}

.yh-pg-title p {
  padding: 10px;
  font-size: 42px;
  text-align: left;
}

.yh-main-story {
  position: relative;
  top: 60px;
  margin-right: 10vw;
}

.yh-stry-intro {
  margin-top: 20px;
  margin-left: 15vw;
}

.yh-stry-bdy {
  margin-left: auto;
  margin-right: auto;
  width: 65%;
}

aside {
  width: 150px;
  margin-right: 20px;
  float: left;
  color: black;
  font-size: 12px;
}

.yh-fnt-clr {
  color: white;
}

.yh-sde-col {
  background-color: rgba(255, 255, 255);
}

.yh-sde-img img {
  border-radius: 5px 5px 0 0;
  height: auto;
  max-width: 100%;
  display:block;
}

.pg-btm-link {
  background-color: rgba(255,0,0);
  border-radius: 0 0 5px 5px;
  height: 30px;
  color: white;
}

.yh-s-contain {
  width: 100%;
  height: 200px;
}

.yh-pg-ftr {
  position: relative;
  top: 80px;
  height: 80px;
  width: 90%;
  background-color: red;
  color: white;
}

.yh-pg-ftr p {
  padding: 10px;
  font-size: 22px;
  text-align: left;
  vertical-align: center;
}

.yh-title-ftr {
  position: absolute;
  margin: 0;
  top: 50%;
  margin-left: 10vw;
  z-index: 10;
  transform: translateY(-50%);
}

.rel-art-btm {
  position: relative;
  top: 100px;
}

@media (min-width: 1200px){
  /*max-width: 1200px;*/
}

@media (min-width: 956px){
  /*max-width: 956px;*/
}
</style>