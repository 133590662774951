<template>
  <div class="yh-divider"></div>
  <div class="yh-news-flash"><p>
    <markDown :source="callToAction" />
  </p></div>
  <div class="yh-divider"></div>

</template>

<script>
import markDown from 'vue3-markdown-it';
import axios from 'axios';

export default{
  data() {
    return {
      callToAction: "",
  }
},
  components: {
    markDown,
  },
  async mounted(){
    try {
      const tmpCalltoAction = await axios.get('https://api.youthhighland.org.uk/api/call-to-action');
      this.callToAction = tmpCalltoAction.data.data.attributes.CallToAction;
    } catch(error) 
    {
      this.error = error;
    }
  },
}
</script>
<style>

</style>
