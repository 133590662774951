<template>
  <div class="yh-intro-section">
    <div class="yh-menu-bar">
      <ul class="yh-lst-zero yh-font-titles">
        <li><router-link to="/Youth">Youth</router-link></li>
        <li><router-link to="/Community">Community</router-link></li>
        <li><router-link to="/Learning">Learning</router-link></li>
        <li><router-link to="/hubs">Hubs</router-link></li>
      </ul>
    </div>
    <div class="yh-video-banner">
      <video loop autoplay muted>
        <source src="https://api.youthhighland.org.uk/uploads/editor_91a6db994e.mp4" type="video/mp4" style="height: 400px;">
      </video>
      <!--<img src="public/images/youth-protest.jpg" style="height: 35px;"> -->
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>
<style>

</style>