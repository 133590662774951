<template>
   <div class="yh-content-grd">
    <div class="section-row" id="community">
      <div class="section-title reverse-title yh-brdr-right yh-font-titles">
        COMMUNITY
      </div>
      <div class="section-text reverse-text">
        <h2>{{ c_HeaderArticle.title }}</h2>
        <p>{{ c_HeaderArticle.summary }}</p>
        <p><router-link :to="{name: 'Article', params:{ id: c_HeaderArticle.id }}">...more</router-link></p>
      </div>
      <div class="section-feature reverse-feature">
        <img v-bind:src="`${ c_HeaderArticle.link }`" style="max-width: 400px" />
      </div>
    </div>
    <div v-if="loading">
      <p>Loading</p>
    </div>
    <div class="section-sub-row" v-else>
      <div class="section-substory" v-for="item in c_Articles" :key="item.id">
        <img class="yh-image-tile" v-bind:src="`${ item.link }`" style="max-width: 300px" />{{ item.title }}
        <p><router-link :to="'/Article/' + item.id">...more</router-link></p>
      </div>
    </div>
  </div>
</template>
<script>
 import axios from 'axios';
 

export default {
  setup() {
    
  },
  data() {
    return {
      c_HeaderArticle: {
        title: "",
        summary: "",
        link: "",
        id: 0,
      },
      c_Articles: [],
      loading: false,
      test: "not worked",
      error: null,
    }
  },
  async mounted() {
    var tmp_Articles;
    try {
      const header_response = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[HeaderArticle][$eq]=true&populate[categories][fields][0]=name&populate[articleImage][fields][0]=url&filters[categories][name][$eq]=Community&sort[0]=publishedAt%3ADesc');
      this.c_HeaderArticle.title = header_response.data.data[0].attributes.Title;
      this.c_HeaderArticle.summary = header_response.data.data[0].attributes.summary;
      this.c_HeaderArticle.link = "https://api.youthhighland.org.uk" + header_response.data.data[0].attributes.articleImage.data.attributes.url;
      this.c_HeaderArticle.id = header_response.data.data[0].id;
    } catch (error)
    {
      this.error = error;
    }
    try {
      const c_Response = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[categories][name][$eq]=Community&populate[articleImage][fields][0]=url&pagination[page]=1&pagination[pageSize]=3');
      tmp_Articles = c_Response.data;

    } catch(error)
    {
      this.error = error;
    }
    console.log(tmp_Articles);
    tmp_Articles.data.forEach(element =>{
      var c_Article = {
        title: "",
        summary: "",
        link: "",
        id: "",
      }
      c_Article.title = element.attributes.Title;
      c_Article.summary = element.attributes.summary;
      c_Article.link = "https://api.youthhighland.org.uk" + element.attributes.articleImage.data.attributes.url;
      c_Article.id = element.id;
      this.c_Articles.push(c_Article);
    });
 
  },
  created() {
  

  },
  methods: {
    
  }
}
</script>
<style scoped>

</style>