<template>
    <div class="yh-s-contain">
    <div class="yh-signup">
      <div v-if="!areSubscribed" class="crumbs">
        <h3>Sign up to our Newsletter</h3>
        <form @submit.prevent="checkSubscriber">
          <div>
            <input
              v-model="firstName"
              class="p-3 my-5 border w-full"
              type="text"
              placeholder="First Name"
            />
          </div>
          <div>
            <input
              v-model="lastName"
              class="p-3 my-5 border w-full"
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div>
            <input
              v-model="emailAddress"
              class="p-3 my-5 border w-full"
              type="email"
              placeholder="email"
            />
          </div>
          <div>
            <button
              class="button--green"
              :disabled="emailAddress === '' || lastName === '' || firstName === ''"
              type="submit"
            >
             Signup
            </button>
          </div>
        </form>
      </div>
      <div v-else>
        <h3>Thanks for Subscribing</h3>
      </div>
    </div>
  </div>
</template>


<script>

export default {

  data() {
    return {
      emailAddress: '',
      firstName: '',
      lastName: '',
      approval: true,
      loading: false,
      areSubscribed: false,
    }
  },
  methods: {
    checkSubscriber(){
      
    },
    createSubscriber(){
     
      },
  },

  setup() {
    
  },
}
</script>
<style scoped>
ul {
  list-style-type: none;
}
</style>