<template>
   <div class="yh-content-grd">
    <div class="section-row" id="youth">
      <div class="section-title yh-brdr-left yh-font-titles">
        YOUTH
      </div>
      <div class="section-text">
        <h2>{{ y_HeaderArticle.title }}</h2>
        <p>{{ y_HeaderArticle.summary }}</p>
        <p class="yh-section-more"><router-link :to="{name: 'Article', params:{ id: y_HeaderArticle.id }}">...more</router-link></p>
      </div>
      <div class="section-feature">
        <img v-bind:src="`${ y_HeaderArticle.link }`" style="max-width: 400px" />
      </div>
    </div>
    <div v-if="loading">
      <p>Loading</p>
    </div>
    <div class="section-sub-row" v-else>
      <div class="section-substory" v-for="item in y_Articles" :key="item.id">
        <img class="yh-image-tile" v-bind:src="`${ item.link }`"  />
        <p><router-link :to="'/Article/' + item.id">{{item.title}} <div class="yh-section-more">...more</div></router-link></p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      y_HeaderArticle: {
        id: 0,
        title: "",
        summary: "",
        link: "",
      },
      y_Articles: [],
      loading: false,
    }
  },
  setup() {
   
  },
  async mounted() {
    var tmp_Articles;
    try {
      const header_response = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[HeaderArticle][$eq]=true&populate[categories][fields][0]=name&populate[articleImage][fields][0]=url&filters[categories][name][$eq]=Youth&sort[0]=publishedAt%3ADesc');
      this.y_HeaderArticle.title = header_response.data.data[0].attributes.Title;
      this.y_HeaderArticle.summary = header_response.data.data[0].attributes.summary;
      this.y_HeaderArticle.link = "https://api.youthhighland.org.uk" + header_response.data.data[0].attributes.articleImage.data.attributes.url;
      this.y_HeaderArticle.id = header_response.data.data[0].id;
    } catch (error)
    {
      this.error = error;
    }
    try {
      const y_Response = await axios.get('https://api.youthhighland.org.uk/api/articles?filters[categories][name][$eq]=Youth&populate[articleImage][fields][0]=url&pagination[page]=1&pagination[pageSize]=3');
      tmp_Articles = y_Response.data;

    } catch(error)
    {
      this.error = error;
    }
    tmp_Articles.data.forEach(element =>{
      var y_Article = {
        title: "",
        summary: "",
        link: "",
        id: "",
      }
      y_Article.title = element.attributes.Title;
      y_Article.summary = element.attributes.summary;
      y_Article.link = "https://api.youthhighland.org.uk" + element.attributes.articleImage.data.attributes.url;
      y_Article.id = element.id;
      this.y_Articles.push(y_Article);
    
    });
  },
  created() {

  },

}
</script>
<style>

</style>