import { createStore } from 'vuex';
import pageData from './modules/pageData';

export default createStore({
  state: {
    articleFilter: 'news',
    isLoggedIn: 'true',
    isAdmin: 'true',
  },
  mutations: {
    setFilter(state, filterType) {
      state.articleFilter = filterType;
    },
  },
  modules: {
    pageData: pageData,
  },
});
