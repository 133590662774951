<template>
  <call-to-action />
  <y-hlogo />
  <intro-section />
  <youth-content />
  <community-content />
  <news-letter />
  <training-content />
  <footer-content />
  
</template>
<script>
import YHlogo from '../components/yh-logo.vue';
import CallToAction from '../components/CallToAction.vue';
import IntroSection from '../components/IntroSection.vue';
import YouthContent from '../components/YouthContent.vue';
import CommunityContent from '../components/CommunityContent.vue';
import NewsLetter from '../components/NewsLetter.vue'
import TrainingContent from '../components/TrainingContent.vue';
import FooterContent from '../components/FooterContent.vue';


export default {
  name: 'home',
  components: {
    YHlogo,
    CallToAction,
    IntroSection,
    YouthContent,
    CommunityContent,
    NewsLetter,
    TrainingContent,
    FooterContent
  },
}
</script>